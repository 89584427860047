*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  max-height: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #0061DF;
  border-radius: 5px;
  height: 7px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #0061Df #f1f1f1;
}
